<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="text-theme-secondary"
                          :height="10" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">

const platformSettings = usePlatformSettings();
platformSettings.setup()
const platformName = platformSettings.platformName
const locale = platformSettings.locale

const t = useI18n().t

useSeoMeta({
  title: platformName.value,
  ogTitle: platformName.value,
  ogLocale: locale.value,
  description: t("general.slogan_" + platformName.value),
  ogDescription: t("general.slogan_" + platformName.value),
  ogImage: useRequestURL().protocol + "//" + useRequestURL().host + "/" + platformName.value + "/some-sharing-" + locale + ".png",
  twitterImage: useRequestURL().protocol + "//" + useRequestURL().host + "/" + platformName.value + "/some-sharing-" + locale + ".png",
  twitterCard: 'summary_large_image'
});

useHead({
  link: [
    { rel: 'icon', href: "/" + platformName.value + '/favicon.ico', sizes: "32x32" },
    { rel: 'icon', href: "/" + platformName.value + '/favicon-16x16.png', sizes: "16x16" },
    { rel: 'icon', href: "/" + platformName.value + '/favicon-16x16.png', sizes: "32x32" },
    { rel: 'apple-touch-icon', href: "/" + platformName.value + '/apple-touch-icon.png' },
    { rel: 'manifest', href: "/" + platformName.value + '/site.webmanifest' }
  ],
  htmlAttrs: {
    lang: locale.value,
  },
  bodyAttrs: {
    class: "theme-" + platformSettings.platformName.value,
  }
});

</script>

<style></style>
