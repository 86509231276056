export const useApiHeaders = () => {
  return useState<any>("apiHeaders", () => {
    const platform = usePlatformSettings().platformName;
    const countryCode = usePlatformSettings().countryCode;
    const locale = usePlatformSettings().locale;

    var headers: any = {
      "Cache-Control": "no-cache",
      "accept-language": locale.value,
      "moveable-platform": platform.value,
      "moveable-countrycode": countryCode.value,
    };

    return headers;
  });
};
