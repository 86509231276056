import type { FetchContext, FetchResponse } from "ofetch";

const handleResponseError401 = () => {
  const auth = useAuth();
  const checkIfAuthenticated = auth.checkIfAuthenticated();
  checkIfAuthenticated.then((authenticated) => {
    if (!authenticated) {
      const router = useRouter();
      auth.setLoggedOut();
      router.push("/login");
    }
  });
};

const movAuthRequest = {
  fetch<T>(
    url: string,
    method: "POST" | "PUT" | "DELETE",
    formData?: FormData
  ) {
    const auth = useAuth();
    const baseUrl = useBaseUrl();
    const apiHeaders = useApiHeaders();

    return $fetch<T>(url, {
      baseURL: baseUrl.value,
      method: method,
      headers: {
        Authorization: `Bearer ${auth.token.value}`,
        ...apiHeaders.value
      },
      body: formData ? formData : undefined,
      onResponseError(context: FetchContext & { response: FetchResponse<T> }): Promise<void> | void {
        if (context.response.status === 401) {
          handleResponseError401();
        }
      }
    });
  },

  get<T>(url: string, params?: any) {
    const auth = useAuth();
    const baseUrl = useBaseUrl();
    const apiHeaders = useApiHeaders();

    return $fetch<T>(url, {
      baseURL: baseUrl.value,
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth.token.value}`,
        ...apiHeaders.value
      },
      params: params
    });
  },

  getWithToken<T>(url: string, token: string, params?: any) {
    const baseUrl = useBaseUrl();
    const apiHeaders = useApiHeaders();

    return $fetch<T>(url, {
      baseURL: baseUrl.value,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        ...apiHeaders.value
      },
      params: params
    });
  },

  post<T>(url: string, formData?: FormData) {
    return this.fetch<T>(url, "POST", formData);
  },

  put<T>(url: string, formData?: FormData) {
    return this.fetch<T>(url, "PUT", formData);
  },

  delete<T>(url: string) {
    return this.fetch<T>(url, "DELETE");
  },
  useFetch<T>(url: string, params?: any) {
    const { token } = useAuth();
    const baseUrl = useBaseUrl();
    const apiHeaders = useApiHeaders();

    return useFetch<T>(url, {
      baseURL: baseUrl.value,
      headers: {
        Authorization: `Bearer ${token.value}`,
        ...apiHeaders.value
      },
      params: params,
      onResponseError(context: FetchContext & { response: FetchResponse<T> }): Promise<void> | void {
        if (context.response.status === 401) {
          handleResponseError401();
        }
      }
    });
  }
};

export default movAuthRequest;
