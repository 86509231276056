import { default as aboutsV5eajZOMoMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/about.vue?macro=true";
import { default as addSpotwP7rqLoPrCMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/addSpot.vue?macro=true";
import { default as index8PDrEz3orJMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/entries/[oldId]/index.vue?macro=true";
import { default as index1m7c3KkmQSMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/entries/index.vue?macro=true";
import { default as guidelinesbPpiue7BkZMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/guidelines.vue?macro=true";
import { default as deleteaccountRSPQilT2Q0Meta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/deleteaccount.vue?macro=true";
import { default as indexS328WL2BRZMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/index.vue?macro=true";
import { default as privacyxhh3L1p53cMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/privacy.vue?macro=true";
import { default as privacykeycloakKZE45K5Z0vMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/privacykeycloak.vue?macro=true";
import { default as termsNWdWaZBVFMMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/terms.vue?macro=true";
import { default as termskeycloak69JFNyM7llMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/termskeycloak.vue?macro=true";
import { default as indexx7MBmga3MaMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/index.vue?macro=true";
import { default as loginXM1ITFaT4kMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/login.vue?macro=true";
import { default as logoutDXmPoMKBkvMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/logout.vue?macro=true";
import { default as map0sLzwyy82DMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/map.vue?macro=true";
import { default as indexzRUbBVJY2eMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/news/[slug]/index.vue?macro=true";
import { default as index9gQOxGPIf0Meta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/news/index.vue?macro=true";
import { default as newsletterZtDMAYivhGMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/newsletter.vue?macro=true";
import { default as partnerpgG2z4A4PWMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/partner.vue?macro=true";
import { default as iframeABgbAKOo6TMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/premium/iframe.vue?macro=true";
import { default as indexs74Uv6sfQKMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/premium/index.vue?macro=true";
import { default as profilenpSn8wyPL5Meta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/profile.vue?macro=true";
import { default as settingsQQgLuMnPclMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/settings.vue?macro=true";
import { default as editiiNNPsX94gMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/[id]/edit.vue?macro=true";
import { default as indexJs0QXrlyxXMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/[id]/index.vue?macro=true";
import { default as indexUoeNr0mpcbMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/index.vue?macro=true";
import { default as stylesr38Ma307AlMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/styles.vue?macro=true";
import { default as _91name_93roG3S7ZwUqMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/town/[name].vue?macro=true";
import { default as _91nameOrOldId_93uYFd6h0d5ZMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/users/[nameOrOldId].vue?macro=true";
export default [
  {
    name: aboutsV5eajZOMoMeta?.name ?? "about",
    path: aboutsV5eajZOMoMeta?.path ?? "/about",
    meta: aboutsV5eajZOMoMeta || {},
    alias: aboutsV5eajZOMoMeta?.alias || [],
    redirect: aboutsV5eajZOMoMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: addSpotwP7rqLoPrCMeta?.name ?? "addSpot",
    path: addSpotwP7rqLoPrCMeta?.path ?? "/addSpot",
    meta: addSpotwP7rqLoPrCMeta || {},
    alias: addSpotwP7rqLoPrCMeta?.alias || [],
    redirect: addSpotwP7rqLoPrCMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/addSpot.vue").then(m => m.default || m)
  },
  {
    name: index8PDrEz3orJMeta?.name ?? "entries-oldId",
    path: index8PDrEz3orJMeta?.path ?? "/entries/:oldId()",
    meta: index8PDrEz3orJMeta || {},
    alias: index8PDrEz3orJMeta?.alias || [],
    redirect: index8PDrEz3orJMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/entries/[oldId]/index.vue").then(m => m.default || m)
  },
  {
    name: index1m7c3KkmQSMeta?.name ?? "entries",
    path: index1m7c3KkmQSMeta?.path ?? "/entries",
    meta: index1m7c3KkmQSMeta || {},
    alias: index1m7c3KkmQSMeta?.alias || [],
    redirect: index1m7c3KkmQSMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/entries/index.vue").then(m => m.default || m)
  },
  {
    name: guidelinesbPpiue7BkZMeta?.name ?? "guidelines",
    path: guidelinesbPpiue7BkZMeta?.path ?? "/guidelines",
    meta: guidelinesbPpiue7BkZMeta || {},
    alias: guidelinesbPpiue7BkZMeta?.alias || [],
    redirect: guidelinesbPpiue7BkZMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/guidelines.vue").then(m => m.default || m)
  },
  {
    name: deleteaccountRSPQilT2Q0Meta?.name ?? "imprint-deleteaccount",
    path: deleteaccountRSPQilT2Q0Meta?.path ?? "/imprint/deleteaccount",
    meta: deleteaccountRSPQilT2Q0Meta || {},
    alias: deleteaccountRSPQilT2Q0Meta?.alias || [],
    redirect: deleteaccountRSPQilT2Q0Meta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/deleteaccount.vue").then(m => m.default || m)
  },
  {
    name: indexS328WL2BRZMeta?.name ?? "imprint",
    path: indexS328WL2BRZMeta?.path ?? "/imprint",
    meta: indexS328WL2BRZMeta || {},
    alias: indexS328WL2BRZMeta?.alias || [],
    redirect: indexS328WL2BRZMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/index.vue").then(m => m.default || m)
  },
  {
    name: privacyxhh3L1p53cMeta?.name ?? "imprint-privacy",
    path: privacyxhh3L1p53cMeta?.path ?? "/imprint/privacy",
    meta: privacyxhh3L1p53cMeta || {},
    alias: privacyxhh3L1p53cMeta?.alias || [],
    redirect: privacyxhh3L1p53cMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacykeycloakKZE45K5Z0vMeta?.name ?? "imprint-privacykeycloak",
    path: privacykeycloakKZE45K5Z0vMeta?.path ?? "/imprint/privacykeycloak",
    meta: privacykeycloakKZE45K5Z0vMeta || {},
    alias: privacykeycloakKZE45K5Z0vMeta?.alias || [],
    redirect: privacykeycloakKZE45K5Z0vMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/privacykeycloak.vue").then(m => m.default || m)
  },
  {
    name: termsNWdWaZBVFMMeta?.name ?? "imprint-terms",
    path: termsNWdWaZBVFMMeta?.path ?? "/imprint/terms",
    meta: termsNWdWaZBVFMMeta || {},
    alias: termsNWdWaZBVFMMeta?.alias || [],
    redirect: termsNWdWaZBVFMMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/terms.vue").then(m => m.default || m)
  },
  {
    name: termskeycloak69JFNyM7llMeta?.name ?? "imprint-termskeycloak",
    path: termskeycloak69JFNyM7llMeta?.path ?? "/imprint/termskeycloak",
    meta: termskeycloak69JFNyM7llMeta || {},
    alias: termskeycloak69JFNyM7llMeta?.alias || [],
    redirect: termskeycloak69JFNyM7llMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/termskeycloak.vue").then(m => m.default || m)
  },
  {
    name: indexx7MBmga3MaMeta?.name ?? "index",
    path: indexx7MBmga3MaMeta?.path ?? "/",
    meta: indexx7MBmga3MaMeta || {},
    alias: indexx7MBmga3MaMeta?.alias || [],
    redirect: indexx7MBmga3MaMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginXM1ITFaT4kMeta?.name ?? "login",
    path: loginXM1ITFaT4kMeta?.path ?? "/login",
    meta: loginXM1ITFaT4kMeta || {},
    alias: loginXM1ITFaT4kMeta?.alias || [],
    redirect: loginXM1ITFaT4kMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutDXmPoMKBkvMeta?.name ?? "logout",
    path: logoutDXmPoMKBkvMeta?.path ?? "/logout",
    meta: logoutDXmPoMKBkvMeta || {},
    alias: logoutDXmPoMKBkvMeta?.alias || [],
    redirect: logoutDXmPoMKBkvMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: map0sLzwyy82DMeta?.name ?? "map",
    path: map0sLzwyy82DMeta?.path ?? "/map",
    meta: map0sLzwyy82DMeta || {},
    alias: map0sLzwyy82DMeta?.alias || [],
    redirect: map0sLzwyy82DMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/map.vue").then(m => m.default || m)
  },
  {
    name: indexzRUbBVJY2eMeta?.name ?? "news-slug",
    path: indexzRUbBVJY2eMeta?.path ?? "/news/:slug()",
    meta: indexzRUbBVJY2eMeta || {},
    alias: indexzRUbBVJY2eMeta?.alias || [],
    redirect: indexzRUbBVJY2eMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/news/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index9gQOxGPIf0Meta?.name ?? "news",
    path: index9gQOxGPIf0Meta?.path ?? "/news",
    meta: index9gQOxGPIf0Meta || {},
    alias: index9gQOxGPIf0Meta?.alias || [],
    redirect: index9gQOxGPIf0Meta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: newsletterZtDMAYivhGMeta?.name ?? "newsletter",
    path: newsletterZtDMAYivhGMeta?.path ?? "/newsletter",
    meta: newsletterZtDMAYivhGMeta || {},
    alias: newsletterZtDMAYivhGMeta?.alias || [],
    redirect: newsletterZtDMAYivhGMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: partnerpgG2z4A4PWMeta?.name ?? "partner",
    path: partnerpgG2z4A4PWMeta?.path ?? "/partner",
    meta: partnerpgG2z4A4PWMeta || {},
    alias: partnerpgG2z4A4PWMeta?.alias || [],
    redirect: partnerpgG2z4A4PWMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/partner.vue").then(m => m.default || m)
  },
  {
    name: iframeABgbAKOo6TMeta?.name ?? "premium-iframe",
    path: iframeABgbAKOo6TMeta?.path ?? "/premium/iframe",
    meta: iframeABgbAKOo6TMeta || {},
    alias: iframeABgbAKOo6TMeta?.alias || [],
    redirect: iframeABgbAKOo6TMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/premium/iframe.vue").then(m => m.default || m)
  },
  {
    name: indexs74Uv6sfQKMeta?.name ?? "premium",
    path: indexs74Uv6sfQKMeta?.path ?? "/premium",
    meta: indexs74Uv6sfQKMeta || {},
    alias: indexs74Uv6sfQKMeta?.alias || [],
    redirect: indexs74Uv6sfQKMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/premium/index.vue").then(m => m.default || m)
  },
  {
    name: profilenpSn8wyPL5Meta?.name ?? "profile",
    path: profilenpSn8wyPL5Meta?.path ?? "/profile",
    meta: profilenpSn8wyPL5Meta || {},
    alias: profilenpSn8wyPL5Meta?.alias || [],
    redirect: profilenpSn8wyPL5Meta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: settingsQQgLuMnPclMeta?.name ?? "settings",
    path: settingsQQgLuMnPclMeta?.path ?? "/settings",
    meta: settingsQQgLuMnPclMeta || {},
    alias: settingsQQgLuMnPclMeta?.alias || [],
    redirect: settingsQQgLuMnPclMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: editiiNNPsX94gMeta?.name ?? "spots-id-edit",
    path: editiiNNPsX94gMeta?.path ?? "/spots/:id()/edit",
    meta: editiiNNPsX94gMeta || {},
    alias: editiiNNPsX94gMeta?.alias || [],
    redirect: editiiNNPsX94gMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexJs0QXrlyxXMeta?.name ?? "spots-id",
    path: indexJs0QXrlyxXMeta?.path ?? "/spots/:id()",
    meta: indexJs0QXrlyxXMeta || {},
    alias: indexJs0QXrlyxXMeta?.alias || [],
    redirect: indexJs0QXrlyxXMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUoeNr0mpcbMeta?.name ?? "spots",
    path: indexUoeNr0mpcbMeta?.path ?? "/spots",
    meta: indexUoeNr0mpcbMeta || {},
    alias: indexUoeNr0mpcbMeta?.alias || [],
    redirect: indexUoeNr0mpcbMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/index.vue").then(m => m.default || m)
  },
  {
    name: stylesr38Ma307AlMeta?.name ?? "styles",
    path: stylesr38Ma307AlMeta?.path ?? "/styles",
    meta: stylesr38Ma307AlMeta || {},
    alias: stylesr38Ma307AlMeta?.alias || [],
    redirect: stylesr38Ma307AlMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/styles.vue").then(m => m.default || m)
  },
  {
    name: _91name_93roG3S7ZwUqMeta?.name ?? "town-name",
    path: _91name_93roG3S7ZwUqMeta?.path ?? "/town/:name()",
    meta: _91name_93roG3S7ZwUqMeta || {},
    alias: _91name_93roG3S7ZwUqMeta?.alias || [],
    redirect: _91name_93roG3S7ZwUqMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/town/[name].vue").then(m => m.default || m)
  },
  {
    name: _91nameOrOldId_93uYFd6h0d5ZMeta?.name ?? "users-nameOrOldId",
    path: _91nameOrOldId_93uYFd6h0d5ZMeta?.path ?? "/users/:nameOrOldId()",
    meta: _91nameOrOldId_93uYFd6h0d5ZMeta || {},
    alias: _91nameOrOldId_93uYFd6h0d5ZMeta?.alias || [],
    redirect: _91nameOrOldId_93uYFd6h0d5ZMeta?.redirect,
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/users/[nameOrOldId].vue").then(m => m.default || m)
  }
]